import React from 'react';
import ValidationAlert from '../ValidationAlert';

function SelectInput({ name, label, type, value, alert, options, setInputs }) {
    let InputClass = "form-select"
    if (typeof alert !== 'undefined' &&  alert != null && alert.length > 0) {
        InputClass = "form-select is-invalid"
    }else if (typeof alert !== 'undefined' &&  alert != null && alert === true) {
        InputClass = "form-select is-valid"
    }

    return (
        <fieldset className="row mb-3">
            <label htmlFor={name} className="col-form-label col-sm-3 pt-0">{label}</label>
            <div className="col-sm-9">
                <select className={InputClass}
                        id={name} name={name} onChange={setInputs} value={value} >
                    <option value="">Choisir...</option>
                    {options && options.map(option => <option key={option.value} value={option.value}>{option.name}</option>)}
                </select>
                <ValidationAlert content={alert} />
            </div>

        </fieldset>
    )
}

export default SelectInput;

