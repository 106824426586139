import {Link} from "react-router-dom";
import React from "react";

function Added({AuthContext}) {

    function NomSalon() {
        let auth = React.useContext(AuthContext)
        return auth.user;
    }

    const RedirectHome = () => {
        document.location.href="/"
    }

  return (
      <div className={"container"}>
          <hr />
          <h1 className={"text-center"}><NomSalon /></h1>
          <hr /><br />
          <div className="alert alert-success"
               role="alert">
              <strong>Merci !</strong> Nous avons bien enregistré vos coordonnées.
          </div>

          <div className={"text-center"}>
              <a className="btn btn-primary" href={"#"} onClick={RedirectHome}>Ajouter un nouvel Étudiant</a>
              </div>

<div className={"text-center decolink"}> <Link to="/logout">Se déconnecter</Link></div>
      </div>
  );
}

export default Added;
