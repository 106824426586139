import * as React from 'react';
import ValidationAlert from '../ValidationAlert';

function TextInput({ name, label, type, value, alert, setInputs, isPassword}) {
    let InputClass = "form-control"
    if (typeof alert !== 'undefined' &&  alert != null && alert.length > 0) {
        InputClass = "form-control is-invalid"
    }else if (typeof alert !== 'undefined' &&  alert != null && alert === true) {
        InputClass = "form-control is-valid"
    }
    type = (isPassword === true) ? "password" : type;

    return (
        <div className="mb-3">
            <label htmlFor={name}
                   className="form-label">{label}</label>
            <input type={type}
                   className={InputClass}
                   id={name}
                   name={name}
                   value={value || ""} onChange={setInputs}
                   />
            <ValidationAlert content={alert} />
        </div>
    )
}

export default TextInput;