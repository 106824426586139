import { useState } from 'react';

const useForm = (initModel, submitCallback, reset) => {

  const [errorMsg, setErrorMsg] = useState(null);
  const [inputs, setInputs] = useState(initModel);
  const [IsLoading, setIsLoading] = useState(false);

  const handleChange = e => {
    e.persist();

    // Mis à jour du champ modifié
    inputs.forEach((i, index) => {
      if (i.name === e.target.name || (e.target.name.indexOf('[') !== -1 && i.name === e.target.name.substring(0, e.target.name.indexOf('[')))) {
        switch(i.type) {
          case 'checkbox':
            i.value = e.target.checked;
            break;
          case 'multicheckbox':


            let deb = e.target.name.indexOf('[');
            let fin = e.target.name.indexOf(']');
            let OptionIndex = e.target.name.substring(deb+1, fin);
            i.options[OptionIndex].checked = e.target.checked;
            i.options[OptionIndex].checked === true &&  i.value.indexOf(i.options[OptionIndex].value) === -1 && i.value.push(i.options[OptionIndex].value);
            i.options[OptionIndex].checked === false &&  i.value.indexOf(i.options[OptionIndex].value) !== -1 && i.value.splice(i.value.indexOf(i.options[OptionIndex].value), 1);
            break;
          default:
            i.value = e.target.value;
        }
        //i.value = i.type === 'checkbox' ? e.target.checked : e.target.value;
        parseInput(i);
        validateInput(i, false);
      }
    });
    // Mise à jour des éventuelles conditions && i.conditions.some((ci) => ci.id === e.target.name)
    let updateStatus = false;
    inputs.forEach((i) => {
      if (typeof i.conditions !== 'undefined'){
        i.conditions.forEach((il, index) => {
          if (  il.id === e.target.name) {
            updateStatus = true;
            if(e.target.value !== il.val){
              il.visible = false;
              i.value = "";
            }else{
              il.visible = true;
            }
          }
        });
      }
    });

    // On affiche ou cache les champs en fonction des résulstats obtenus lors de l'analyse précédente
    if (updateStatus === true){
      inputs.forEach((i) => {
        if (typeof i.conditions !== 'undefined'){
          if(i.conditions.every((ci) => ci.visible === true)){
            i.visible = true;
          }else{
            i.visible = false;
          }
        }
      });
    }

    setInputs([...inputs]);
  };

  const setErrorMsgExt = (html) => {
    setIsLoading(false);
    setErrorMsg(html);
  }

  const handleSubmit = e => {
    setIsLoading(true);
    setErrorMsg(null);
    e && e.preventDefault();
    inputs.forEach(i => validateInput(i, true));
    if(inputs.some(i => i.alert && i.alert !== true)){
      setIsLoading(false);
      setInputs([...inputs]);
    }else{
      submitCallback(setErrorMsgExt, [...inputs]);
    }
  };

  const parseInput = input => input.value = input.parseFun ? input.parseFun(input.value) : input.value;

  const validateInput = (input, defAlert) => {
    if(typeof input.visible === 'undefined' || input.visible === true) {
      let alert = defAlert;
      input.validators && input.validators.forEach(v => alert = v.isValidFun && !v.isValidFun(input.value) ? v.alert : alert);
      input.alert = alert;
    }
  }

  return [inputs, handleChange, handleSubmit, IsLoading, errorMsg]
};

export default useForm;