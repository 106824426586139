import React from 'react';
import ValidationAlert from "../ValidationAlert";

function RadioInput({ name, label, type, value, alert, options, setInputs }) {
    let InputClass = "form-check-input"
    if (typeof alert !== 'undefined' &&  alert != null && alert.length > 0) {
        InputClass = "form-check-input is-invalid"
    }else if (typeof alert !== 'undefined' &&  alert != null && alert === true) {
        InputClass = "form-check-input is-valid"
    }

    return (
                <fieldset className="row mb-3">
            <legend className="col-form-label col-sm-3 pt-0">{label}</legend>
            <div className="col-sm-9">
                {options && options.map((option, i) => {
                    let EndError = ""
                    if(i === (options.length-1)) {
                        EndError = <ValidationAlert content={alert} />
                    }
                    return <div className="form-check" key={option.value}>
                        <input className={InputClass}
                               type={type}
                               name={name}
                               id={option.name}
                               value={option.value}
                               checked={value === option.value}
                               onChange={setInputs}
                        />
                        <label className="form-check-label"
                               htmlFor={option.name}>
                            {option.name}
                        </label>
                        {EndError}
                    </div>;
                })

                }

            </div>


        </fieldset>

    )
}
export default RadioInput;
    /*<div>
        <div className="uk-form-label uk-text-large">{label}</div>
        {options && options.map(option =>
            <div key={option.value}>
                <input id={option.name} name={name} type={type} value={option.value} checked={value === option.value} onChange={setInputs} className="uk-radio" />
                <label htmlFor={option.name} className="uk-margin-small-left">{option.name}</label>
            </div>)}
        <ValidationAlert content={alert} />
    </div>*/