
import { checkAtLeastLength, checkEmailPattern, checkIsfilled } from '../services/inputValidator';

const loginModel = [{
    name: 'nom',
    label: 'Nom du Salon',
    type: 'text',
    //parseFun: parseOnlyUTF8Printable,
    validators: [{
        //  id: 'email-required',
        isValidFun: checkIsfilled,
        alert: 'Le champs est vide.'
    }]
},{
    name: 'code',
    label: 'Code d\'accès',
    type: 'text',
    isPassword: true,
    //parseFun: parseOnlyUTF8Printable,
    validators: [{
        isValidFun: checkIsfilled,
        alert: 'Le champs est vide.'
    }]
}
];

export default loginModel;