import React, {useEffect, route} from 'react';
import FormPanel from './FormPanel';
import registerFormModel from '../models/registrationModel';
import CsrfContext from "../services/csrfProvider";
import {useNavigate} from "react-router-dom";

function RegisterForm() {
  let Csrf = React.useContext(CsrfContext);
  let navigate = useNavigate();
  let CurModel= registerFormModel.map(a => {return {...a}});;



  const submitCallback = (errorcallback, model) => {
    let PostAr = {};

    console.log(model);

    model.forEach(m => {
      PostAr[m.name] = m.value;
    })

    async function CallBackAddstudent(resp) {
      if(resp.ok === true){
        const json = await resp.json();
        //console.log(json);
        if(json.Status === "OkStuAdded"){
          // L'utilisateur a bien été ajouté.
          navigate("/added");
        }else{
          // Todo Error
        }

      }
    }

    Csrf.Post("/auth/addstudent", JSON.stringify(PostAr), CallBackAddstudent)
    //console.log(registerFormModel);
    //alert(registerFormModel.map(m => m.name + ': ' + m.value + ',\n').join(''));
  }

  return (

          <FormPanel title="ESITC Paris" btnName="Enregistrer" btnLoading="Enregistrement en cours..." submitCallback={submitCallback}  model={CurModel}   />

  );
}

export default RegisterForm;
