import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter,
    Routes,
    Route, useLocation, Navigate, useNavigate
} from "react-router-dom";
import './custom.scss';
import App from './App';
import Login from "./Login";
import Logout from "./Logout";
import Header from "./header";
import {CsrfProvider} from "./services/csrfProvider";
import loginModel from "./models/loginModel";
import Added from "./Added";
//import reportWebVitals from './reportWebVitals';

const AuthContext = React.createContext();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <CsrfProvider>

                <Header />
                <AuthProvider>
                    <Routes>
                        <Route path="/" element={
                            <RequireAuth>
                                <App AuthContext={AuthContext} />
                            </RequireAuth>
                        } />
                        <Route path="/added" element={
                            <RequireAuth>
                                <Added AuthContext={AuthContext} />
                            </RequireAuth>
                        } />

                        <Route path="/login" element={<Login AuthContext={AuthContext} />} />
                        <Route path="/logout" element={<Logout AuthContext={AuthContext}  />} />
                        <Route
                            path="*"
                            element={
                                <main style={{ padding: "1rem" }}>
                                    <p>404 - Cette page n'existe pas.</p>
                                </main>
                            }
                        />


                    </Routes>

                </AuthProvider>
            </CsrfProvider>
        </BrowserRouter>
    </React.StrictMode>
);
/*
ReactDOM.render(

      <React.StrictMode>
        <BrowserRouter>
            <CsrfProvider>

                    <Header />
                <AuthProvider>
                  <Routes>
                    <Route path="/" element={
                      <RequireAuth>
                        <App AuthContext={AuthContext} />
                      </RequireAuth>
                    } />
                      <Route path="/added" element={
                          <RequireAuth>
                              <Added AuthContext={AuthContext} />
                          </RequireAuth>
                      } />

                    <Route path="/login" element={<Login AuthContext={AuthContext} />} />
                    <Route path="/logout" element={<Logout AuthContext={AuthContext}  />} />
                      <Route
                          path="*"
                          element={
                              <main style={{ padding: "1rem" }}>
                                  <p>404 - Cette page n'existe pas.</p>
                              </main>
                          }
                      />


                  </Routes>

                </AuthProvider>
            </CsrfProvider>
        </BrowserRouter>
      </React.StrictMode>
  ,
    document.getElementById('root')
);*/

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [user, setUser] = React.useState({ Salon: null, Auth: false, Init: false });
  let navigate = useNavigate();

  let signin = (newUser: string, callback: VoidFunction) => {
      setUser({ Salon: newUser, Auth: false});
      callback();
  };

  let signout = (callback: VoidFunction) => {
      setUser({ Salon: null, Auth: false, Init: true });
      callback();
  };



  useEffect(() => {
      if(user.Init === false){

          async function GetAuthStatus() {
              let resp = await fetch('/api/getauth');
                if(resp.ok === true){
                    const json = await resp.json();
                    if(json.Status === "IsLogged"){
                        setUser({ Salon: json.Salon, Auth: true, Init: true  });
                        navigate("/", { replace: true });
                    }else{
                        setUser({ Init: true });
                    }

              }else{
                    setErrorMsg("<strong>Erreur - Impossible de se connecter.</strong><br />Veuillez vérifier votre connexion internet puis essayer à nouveau.")
              }
          }
          GetAuthStatus();

      }
  });
    let value = { user: user.Salon, signin, signout };
    if(errorMsg !== null){
        return <div className={"container"}><div className={"alert alert-danger"}  role="alert" dangerouslySetInnerHTML={{__html: errorMsg}} /></div>
    }
  if(user.Init === false){
      console.log("Render");
      return <div className={"text-center"}><br />Initialisation de l'application. Patienter.</div>;
  }else{
      return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  }

}



function useAuth() {
  return React.useContext(AuthContext);
}
/*
function AuthStatus() {
  let auth = useAuth();
  let navigate = useNavigate();

  if (!auth.user) {
    return <p>You are not logged in.</p>;
  }

  return (
      <p>
        Welcome {auth.user}!{" "}
        <button
            onClick={() => {
              auth.signout(() => navigate("/"));
            }}
        >
          Sign out
        </button>
      </p>
  );
}*/

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
/*<Route
              path="*"
              element={
                  <main style={{ padding: "1rem" }}>
                      <p>There's nothing here!</p>
                  </main>
              }
          />*/