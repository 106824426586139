import React from "react";

const CsrfContext = React.createContext();
let csrfToken = null;

function CsrfProvider({ children }: { children: React.ReactNode }) {


    let Post = (url: string, data: string, callback, rescuecall) => {
            if(csrfToken === null){
                   GetCsrfToken((response) => {
                        if(response.ok === true){
                            Fetcher(url,data, callback, rescuecall);
                        }else{
                            callback(response);
                        }

                   });

            }else{
                Fetcher(url,data, callback, rescuecall);
            }
    }

    let GetCsrfToken = (callback) => {
        fetch("/api/csrf").then(response => {
            if (response.ok) {
                csrfToken = response.headers.get("x-csrf-token");
                callback(response);

            } else {
                callback(response);
            }
        });
    }

    let Fetcher = (url: string, data: string, callback, rescuecall) => {
        rescuecall = (typeof rescuecall !== 'undefined');
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authenticity-CSRF-Token': csrfToken
            },
            body: data
        }).then(response => {
            if (!response.ok && response.headers.get("x-csrf-error") === "csrf-not-validated" && !rescuecall) {
                console.log("CSRF incorrect. Réinitialisation du token");
                csrfToken = null;
                Post(url, data, callback, true)
            }else{
                if(rescuecall && response.headers.get("x-csrf-error") === "csrf-not-validated"){
                    console.log("La deuxième requête avec le nouveau CSRF a échoué.");
                }
                callback(response);
            }
        });
    };

    let value = { Post };
    return  (
        <CsrfContext.Provider
            value={value}
        >
            {children}
        </CsrfContext.Provider>
    )
}

export { CsrfProvider };

// I make this default since it will probably be exported most often.
export default CsrfContext;