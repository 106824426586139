import {useNavigate} from "react-router-dom";
import React from "react";
import loginModel from "./models/loginModel";
import FormPanel from "./components/FormPanel";
import CsrfContext from "./services/csrfProvider";

export default function Login({AuthContext}) {
    let navigate = useNavigate();
    let Auth = React.useContext(AuthContext);
    let Csrf = React.useContext(CsrfContext);

    const submitCallback = (setErrorMsg, inputs) => {

        let NomSalon, Code;
        loginModel.forEach(m => {
            if(m.name === "nom"){
                NomSalon = m.value;
            } else if(m.name === "code"){
                Code = m.value;
            }
        });

        async function CallBackLogin(resp) {
            if(resp.ok === true){
                const json = await resp.json();
                console.log(json);
                if(json.Status === "BadLogin"){
                    setErrorMsg("<strong>Votre code d'accès est incorrect.</strong>");
                }else{
                    Auth.signin(json.Salon, () => {
                        loginModel.forEach(m => {
                            m.value = "";
                        });
                        console.log("Redirect.")
                        navigate("/", { replace: true });
                    });
                }

            }else{
                console.log("Erreur reseau");
                setErrorMsg("<strong>Une erreur réseau a été détectée.</strong><br> Veuillez vérifier que vous êtes bien connecté à internet et réessayer ultérieurement.");
            }
        }

        Csrf.Post("/api/login", JSON.stringify({NomSalon: NomSalon, Code: Code}), CallBackLogin);
    }
    return (<>
            <div className={"container"}>
                <main style={{ padding: "1rem 0" }}>
                    <h2>S'identifier</h2>

                    <FormPanel title="ESITC Paris" btnName="Se Connecter" btnLoading={"En cours..."} submitCallback={submitCallback} model={loginModel} />


                </main>

            </div>
    </>
    );
}/*<Link to="/">App</Link>
                    <Link to="/logout">Logout</Link>*/