import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import CsrfContext from "./services/csrfProvider";

export default function Logout({AuthContext}) {
    let navigate = useNavigate();
    let Auth = React.useContext(AuthContext)
    let Csrf = React.useContext(CsrfContext);
    useEffect(() => {
        Csrf.Post("/api/logout", "", () => {
            Auth.signout(() => {
                navigate("/");
            });
        });

    });
    return "";
}