import React, {useEffect} from 'react';
import useForm from '../hooks/useForm';
import TextInput from './inputs/TextInput';
import RadioInput from './inputs/RadioInput';
import TextareaInput from './inputs/TextareaInput';
import MulticheckboxInput from './inputs/MulticheckboxInput';
import SelectInput from './inputs/SelectInput';
import BtnSubmit from "./inputs/BtnSubmit";

function FormPanel({ title, btnName, btnLoading, submitCallback, model }) {
  const [inputs, setInputs, setSubmit, IsLoading, errorMsg] = useForm(model, submitCallback);

  const Components = { TextInput, RadioInput, TextareaInput, MulticheckboxInput, SelectInput };

  const capitalize = expression => expression.charAt(0).toUpperCase() + expression.slice(1);



  const renderInput = input => {
    if(typeof input.visible === 'undefined' || input.visible === true){
      const Component = Components[capitalize(input.type) + 'Input'];
      return <Component key={input.name} setInputs={setInputs} {...input} />;
    }else{
      return "";
    }

  }

   // console.log("RenderVue");
  return (
      <form noValidate autoComplete="off">
        <section>
          {inputs.map(input => renderInput(input))}
        </section>

          {errorMsg && errorMsg.length > 0 && <div className={"alert alert-danger"}
               role="alert" dangerouslySetInnerHTML={{__html: errorMsg}}></div>}

          <div className={"text-center mt-4"}>

              <BtnSubmit btnName={btnName} btnLoading={btnLoading} submitCallback={setSubmit} IsLoading={IsLoading} />
          </div>
      </form>

  )
}

export default FormPanel;