import RegisterForm from "./components/RegisterForm";
import {Link} from "react-router-dom";
import React from "react";

function App({AuthContext}) {

    function NomSalon() {
        let auth = React.useContext(AuthContext)
        return auth.user;
    }

  return (
      <div className={"container"}>
          <hr />
          <h1 className={"text-center"}><NomSalon /></h1>
          <hr />
       <RegisterForm />
<div className={"text-center decolink"}> <Link to="/logout">Se déconnecter</Link></div>
      </div>
  );
}

export default App;
