
import { checkAtLeastLength, checkEmailPattern, checkIsfilled } from '../services/inputValidator';

const registrationModel = [{
    name: 'Nom',
    label: 'Nom',
    type: 'text',
    //parseFun: parseOnlyUTF8Printable,
    validators: [{
     //   id: 'nom-length',
        isValidFun: expression => checkAtLeastLength(expression, 2),
        alert: 'Le champs est trop court.'
    }, {
        //  id: 'email-required',
        isValidFun: checkIsfilled,
        alert: 'Le champs est vide.'
    }]
},{
    name: 'Prenom',
    label: 'Prénom',
    type: 'text',
    //parseFun: parseOnlyUTF8Printable,
    validators: [{
     //   id: 'prenom-length',
        isValidFun: expression => checkAtLeastLength(expression, 2),
        alert: 'Le champs est trop court.'
    }, {
        //  id: 'email-required',
        isValidFun: checkIsfilled,
        alert: 'Le champs est vide.'
    }]
}, {
    name: 'Mail',
    label: 'Email',
    type: 'text',
   // parseFun: parseOnlyUTF8Printable,
    validators: [{
      //  id: 'mail-pattern',
        isValidFun: checkEmailPattern,
        alert: 'Votre Email n\'est pas valide'
    }, {
      //  id: 'email-required',
        isValidFun: checkIsfilled,
        alert: 'Le champs Email est vide.'
    }]
},  {
    name: 'Formation',
    label: 'Formation actuelle',
    type: 'radio',
    options: [
        { value: 'premiere', name: 'Première' },
        { value: 'terminale', name: 'Terminale' },
        { value: 'etudeSup', name: ' BAC+1/2/3' }
    ],
    validators: [{
        isValidFun: checkIsfilled,
        alert: 'Veuillez choisir une formation'
    }]
},
/*
*  Gestion pour les classes en première
*
* */
    {
        name: 'FormationPremiere',
        label: 'Première',
        type: 'radio',
        conditions: [{id: 'Formation', val: 'premiere'}],
        visible: false,
        options: [
            { value: 'generale', name: 'Générale' },
            { value: 'sti2d', name: 'STI2D' }
        ],
        validators: [{
            isValidFun: checkIsfilled,
            alert: 'Veuillez choisir votre filière.'
        }]
    },
    /*
*  Gestion pour les classes en Terminale
*
* */
    {
        name: 'FormationTerminale',
        label: 'Terminale',
        type: 'radio',
        conditions: [{id: 'Formation', val: 'terminale'}],
        visible: false,
        options: [
            { value: 'generale', name: 'BAC Général' },
            { value: 'sti2d', name: 'BAC STI2D' }
        ],
        validators: [{
            isValidFun: checkIsfilled,
            alert: 'Veuillez choisir votre filière.'
        }]
    },
    /*
*  Gestion pour les classes en Terminale // Spé Générale
*
* */
    {

        name: 'Termspe1',
        label: 'Spécialité N°1',
        type: 'select',
        conditions: [{id: 'Formation', val: 'terminale'}, {id: 'FormationTerminale', val: 'generale'}],
        visible: false,
        options: [
            { value: 'CINEMA', name: 'CINEMA-AUDIOVISUEL' },
            { value: 'CIRQUE', name: 'ARTS DU CIRQUE' },
            { value: 'DANSE', name: 'DANSE' },
            { value: 'MUSIQUE', name: 'MUSIQUE' },
            { value: 'ART_PLA', name: 'ARTS PLASTIQUES' },
            { value: 'THEATRE', name: 'THEATRE' },
            { value: 'BIO_ECO', name: 'BIOLOGIE-ECOLOGIE' },
            { value: 'HIS_ART', name: 'HISTOIRES DES ARTS' },
            { value: 'HIST_GE', name: 'HISTOIRE-GEOGRAPHIE, GEOPOLITIQUES ET SCIENCES POLITIQUES' },
            { value: 'LIT_GRE', name: 'LITTERATURE ET LANGUES ET CULTURES DE L’ANTIQUITE - GREC' },
            { value: 'LIT_LAT', name: 'LITTERATURE ET LANGUES ET CULTURE DE L’ANTIQUITE - LATIN' },
            { value: 'LIT_PHI', name: 'HUMANITES, LITTERATURE ET PHILOSOPHIE' },
            { value: 'LLCER', name: 'LANGUES, LITTERATURE ET CULTURES ETRANGERES ET REGIONALES' },
            { value: 'MATHS', name: 'MATHEMATIQUES' },
            { value: 'NUMERIQ', name: 'NUMERIQUE ET SCIENCES INFORMATIQUES' },
            { value: 'PHY_CHI', name: 'PHYSIQUE-CHIMIE' },
            { value: 'SC_INGE', name: 'SCIENCES DE L’INGENIEUR ET SCIENCES PHYSIQUES' },
            { value: 'SES', name: 'SCIENCES ECONOMIQUES ET SOCIALES' },
            { value: 'SVT', name: 'SCIENCES DE LA VIE ET DE LA TERRE' },
        ],
        validators: [{
            isValidFun: checkIsfilled,
            alert: 'Veuillez choisir votre spécialité.'
        }]
    },
    {
        name: 'Termspe2',
        label: 'Spécialité N°2',
        type: 'select',
        conditions: [{id: 'Formation', val: 'terminale'}, {id: 'FormationTerminale', val: 'generale'}],
        visible: false,
        options: [
            { value: 'CINEMA', name: 'CINEMA-AUDIOVISUEL' },
            { value: 'CIRQUE', name: 'ARTS DU CIRQUE' },
            { value: 'DANSE', name: 'DANSE' },
            { value: 'MUSIQUE', name: 'MUSIQUE' },
            { value: 'ART_PLA', name: 'ARTS PLASTIQUES' },
            { value: 'THEATRE', name: 'THEATRE' },
            { value: 'BIO_ECO', name: 'BIOLOGIE-ECOLOGIE' },
            { value: 'HIS_ART', name: 'HISTOIRES DES ARTS' },
            { value: 'HIST_GE', name: 'HISTOIRE-GEOGRAPHIE, GEOPOLITIQUES ET SCIENCES POLITIQUES' },
            { value: 'LIT_GRE', name: 'LITTERATURE ET LANGUES ET CULTURES DE L’ANTIQUITE - GREC' },
            { value: 'LIT_LAT', name: 'LITTERATURE ET LANGUES ET CULTURE DE L’ANTIQUITE - LATIN' },
            { value: 'LIT_PHI', name: 'HUMANITES, LITTERATURE ET PHILOSOPHIE' },
            { value: 'LLCER', name: 'LANGUES, LITTERATURE ET CULTURES ETRANGERES ET REGIONALES' },
            { value: 'MATHS', name: 'MATHEMATIQUES' },
            { value: 'NUMERIQ', name: 'NUMERIQUE ET SCIENCES INFORMATIQUES' },
            { value: 'PHY_CHI', name: 'PHYSIQUE-CHIMIE' },
            { value: 'SC_INGE', name: 'SCIENCES DE L’INGENIEUR ET SCIENCES PHYSIQUES' },
            { value: 'SES', name: 'SCIENCES ECONOMIQUES ET SOCIALES' },
            { value: 'SVT', name: 'SCIENCES DE LA VIE ET DE LA TERRE' },
        ],
        validators: [{
            isValidFun: checkIsfilled,
            alert: 'Veuillez choisir votre spécialité.'
        }]
    },
    /*
*  Gestion pour les classes en Terminale // STI2D
*
* */
    {

        name: 'Termspe1',
        label: 'Spécialité',
        type: 'radio',
        conditions: [{id: 'Formation', val: 'terminale'}, {id: 'FormationTerminale', val: 'sti2d'}],
        visible: false,
        options: [
            { value: 'IIDD', name: 'Ingénierie, Innovation & Développement Durable' },
            { value: 'PCM', name: 'Physique-Chimie & Mathématiques' },
        ],
        validators: [{
            isValidFun: checkIsfilled,
            alert: 'Veuillez choisir votre spécialité.'
        }]
    },
    /*
    Formation Etude Sup
     */
    {
        name: 'FormationSup',
        label: 'Formation',
        type: 'radio',
        conditions: [{id: 'Formation', val: 'etudeSup'}],
        visible: false,
        options: [
            // { value: 'tc1', name: '1ere année du cycle préparatoire' },
            { value: 'BUT', name: 'BUT GCCD' },
            { value: 'BTS', name: 'BTS' },
            { value: 'BACH', name: 'Bachelors' },
            { value: 'CPGE', name: 'CPGE' },
            { value: 'L3GC', name: 'L3 Génie Civil' },
            { value: 'AUTRE', name: 'Autre' },
        ],
        validators: [{
            isValidFun: checkIsfilled,
            alert: 'Veuillez définir votre formation.'
        }]
    },
    /* Annnée BUT */
    {
        name: 'AnneeEtude',
        label: 'Année BUT',
        type: 'radio',
        conditions: [{id: 'FormationSup', val: 'BUT'}],
        visible: false,
        options: [
            // { value: 'tc1', name: '1ere année du cycle préparatoire' },
            { value: '1ERE', name: '1ère année' },
            { value: '2EME', name: '2ème année' },
            { value: '3EME', name: '3ème année' },
        ],
        validators: [{
            isValidFun: checkIsfilled,
            alert: 'Veuillez définir votre année.'
        }]
    },
    /* Option BTS */
    {
        name: 'OptionBTS',
        label: 'Options BTS',
        type: 'radio',
        conditions: [{id: 'FormationSup', val: 'BTS'}],
        visible: false,
        options: [
            // { value: 'tc1', name: '1ere année du cycle préparatoire' },
            { value: 'BAT', name: 'BAT' },
            { value: 'TP', name: 'TP' },
        ],
        validators: [{
            isValidFun: checkIsfilled,
            alert: 'Veuillez définir votre option.'
        }]
    },
    /* Année BTS */
    {
        name: 'AnneeEtude',
        label: 'Année BTS',
        type: 'radio',
        conditions: [{id: 'FormationSup', val: 'BTS'}],
        visible: false,
        options: [
            // { value: 'tc1', name: '1ere année du cycle préparatoire' },
            { value: '1ERE', name: '1ère année' },
            { value: '2EME', name: '2ème année' },
        ],
        validators: [{
            isValidFun: checkIsfilled,
            alert: 'Veuillez définir votre année.'
        }]
    },
    /* Année Bachelors */
    {
        name: 'AnneeEtude',
        label: 'Année Bachelors',
        type: 'radio',
        conditions: [{id: 'FormationSup', val: 'BACH'}],
        visible: false,
        options: [
            // { value: 'tc1', name: '1ere année du cycle préparatoire' },
            { value: '1ERE', name: '1ère année' },
            { value: '2EME', name: '2ème année' },
            { value: '3EME', name: '3ème année' },
        ],
        validators: [{
            isValidFun: checkIsfilled,
            alert: 'Veuillez définir votre année.'
        }]
    },
    /* Année CPGE */
    {
        name: 'AnneeEtude',
        label: 'Année CPGE',
        type: 'radio',
        conditions: [{id: 'FormationSup', val: 'CPGE'}],
        visible: false,
        options: [
            // { value: 'tc1', name: '1ere année du cycle préparatoire' },
            { value: '1ERE', name: '1ère année' },
            { value: '2EME', name: '2ème année' },
        ],
        validators: [{
            isValidFun: checkIsfilled,
            alert: 'Veuillez définir votre année.'
        }]
    },
/* Formation : Autre */
    {
        name: 'FormAutre',
        label: 'Autre Formation',
        type: 'text',
        conditions: [{id: 'FormationSup', val: 'AUTRE'}],
        visible: false,
        validators: [{
            //  id: 'email-required',
            isValidFun: checkIsfilled,
            alert: 'Veuillez préciser votre formation actuelle.'
        }]
    },
    {
        name: 'FormationSouhaitee',
        label: 'Formation souhaitée',
        type: 'multicheckbox',
        conditions: [],
        value: [],
        visible: true,
        options: [
            { value: 'tc1', name: 'Cycle Préparatoire - 1ere année' },
            { value: 'tc2', name: 'Cycle Préparatoire - 2ere année' },
            { value: 'tc3', name: 'Cycle Ingénieur sous statut Étudiant - Campus Arcueil' },
            { value: 'tc3a-arc', name: 'Cycle Ingénieur par apprentissage - Campus Arcueil' },
            { value: 'tc3a-egl', name: 'Cycle Ingénieur par apprentissage - Campus Égletons' },

            { value: 'tc1-arch', name: 'Double cursus : Ingénieur-Architecte' },
        ],
        validators: [{
            isValidFun: checkIsfilled,
            alert: 'Veuillez choisir la formation souhaitée.'
        }]
    },
    {
        name: 'PrefCom',
        label: 'Préférences de communication\n',
        type: 'multicheckbox',
        value: [],
        options: [
            // { value: 'tc1', name: '1ere année du cycle préparatoire' },
            { value: 'jpo', name: 'Invitations Journées Portes Ouvertes' },
            { value: 'journalESITC', name: 'Envoi numérique du journal de l\'ESITC Paris' },
        ],
        //validators: []
    }

];

export default registrationModel;